import React from "react"

import Style from "./style.module.css"

import Logo from "../../images/logo.svg"
import { Link } from "react-scroll";

const Header = () => {

	let items = [{"text": "Sobre", "id": "sobre"}, {"text": "Modelo de Negócios", "id": "modelo"}, {"text": "O que é um FII", "id": "oquee"}, {"text": "Vantagens", "id": "vantagens"}, {"text": "Empreendimentos", "id": "empreendimentos"}, {"text": "Aquisição", "id": "aquisicao"}]

	let menu_items = items.map((item, index) => {
		return <li className={Style.menu_item} key={index}>
				<Link activeClass="active"
			    to={item.id}
			    spy={true}
			    smooth={true}
			    offset={-70}
			    duration= {500} 
			    className={Style.menu_link}> 
			    	{item.text}
			    </Link> 
			 </li>
	})

	return (
		<header className={Style.header}>
			<div className="container flex">
				<a href="https://alugue.luggo.com.br/"> <Logo/> </a>
				<ul className={Style.menu}>
					{menu_items}
				</ul>
			</div>
		</header>
	)
}

export default Header