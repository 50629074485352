import React from "react"

import Style from "./style.module.css"

import Logo from "../../images/logo_mrv.svg"
import Facebook from "../../images/facebook.svg"
import Instagram from "../../images/instagram.svg"
import Twitter from "../../images/twitter.svg"
import Luggo from "../../images/logo.svg"

const MediaIcons = () => {
  return (
    <div className={Style.footer_media_icons} style={{ "padding-top": "10px" }}>
      <a href="https://www.facebook.com/alugueluggo/" target="_blank"><Facebook/></a>
      <a href="https://www.instagram.com/alugueluggo/" target="_blank"><Instagram/></a>
      <a href="https://twitter.com/alugueluggo" target="_blank"><Twitter/></a>
    </div>
  )
}
const Footer = () => {
  return (
    <div>
      <footer className={Style.footer}>
        <div className="container">
          <div className={Style.container}>
            <div className={Style.content_footer}>
              <Logo />
              <p>Luggo é uma startup da MRV</p>
              <MediaIcons />
            </div>

            <div className={Style.content_footer}>
              <b>Política de Privacidade</b>
              <a href="https://alugue.luggo.com.br/termoseprivacidade" target="_blank">Termos e condições</a>
            </div>

            <div className={Style.content_footer}>
              <b>Central Ajuda</b>
              <a href="https://alugue.luggo.com.br/faq" target="_blank">FAQ</a>
              <a href="https://alugue.luggo.com.br/contato" target="_blank">Contato</a>
            </div>

            <div className={Style.content_footer}>
              <b>Institucional</b>
              <a href="https://alugue.luggo.com.br/sobrenos" target="_blank">Sobre a Luggo</a>
            </div>

            <div className={Style.content_footer}>
              <b>Compromisso Social</b>
              <a href="https://www.mrv.com.br/sustentabilidade/pt/mrv-sustentavel/" target="_blank">Sustentabilidade</a>
              <a href="https://www.institutomrv.com.br/pt/" target="_blank">Instituto MRV</a>
            </div>

            <div className={Style.content_footer}>
              <b>Investidores</b>
              <a href="https://ri.mrv.com.br/">Relacionamento</a>
              <a href="https://ri.mrv.com.br/ListResultados/Central-de-Resultados?=r0Kr7/51+2K3bi4bQ2t63g==" target="_blank">Central de Resultados</a>
              <a href="https://ri.mrv.com.br/ShowCanal/Estrategia-e-Vantagens-Competitivas?=HvVmJzMVRD8s+SfskQDQVw==" target="_blank">Estratégia e Vantagens</a>
            </div>
          </div>
        </div>
      </footer>
      <div className={Style.copyright}>
        <Luggo />
        <div className={Style.copyright_content}>
          <p> Copyright @ 2019 Luggo todos direitos reservados - Política de Privacidade </p>
        </div>
      </div>
    </div>
  )
}

export default Footer
