import React from "react"

import "./normalize.css"
import "./common.css"

import Header from "../Header"
import Footer from "../Footer"

const Layout = (props) => {
	return (
		<div>
			<Header/>
			{props.children}
			<Footer/>
		</div>
	)
}

export default Layout